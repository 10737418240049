<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('academic_year')">
                    <academic-years-selectbox v-model="filter.academic_year"></academic-years-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('faculty')">
                    <faculty-selectbox v-model="filter.faculty_code"/>
                </b-form-group>
                <b-form-group :label="$t('department')">
                    <department-selectbox v-model="filter.department_code"
                                          :faculty_code="filter.faculty_code"
                    />
                </b-form-group>
                <b-form-group :label="$t('program')">
                    <program-selectbox v-model="filter.program_code"
                                       :faculty_code="filter.faculty_code"
                                       :department_code="filter.department_code"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('report_type')">
                    <multi-selectbox :allow-empty="false" :multiple="false"
                                     :options="responseTypeOptions"
                                     v-model="responseType"></multi-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group class="mb-0">
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary" @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    // Components
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox'
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
    import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox';

    // Services
    import StudentReportService from '@/services/StudentReportService'

    // Other
    import qs from 'qs'

    export default {
        components: {
            FacultySelectbox,
            DepartmentSelectbox,
            ProgramSelectbox,
            AcademicYearsSelectbox
        },
        data() {
            return {
                responseTypeOptions: [
                    {value: 'page', text: this.$t('page')},
                    {value: 'excel', text: this.$t('excel')},
                    {value: 'pdf', text: 'PDF'},
                ],
                selected: null,
                filter: {},
                responseType:'page',
                reportName: this.$t('semester_status_analysis')
            }
        },
        methods: {
            getReport() {
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                        response_type: this.responseType,
                        page: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                return StudentReportService.semesterStatusAnalysis(config)
                    .then(this.callback)
                    .catch(e=>this.showErrors(e))
            },
            callback(response) {
                if (this.responseType == 'page') {
                    this._openFile(response);
                }
                else if (this.responseType == 'excel') {
                    this._downloadFile(response, this.reportName + '.xlsx');
                }
                else if (this.responseType == 'pdf') {
                    this._downloadFile(response, this.reportName + '.pdf');
                }
            }
        }
    }
</script>
